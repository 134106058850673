import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import TabBar from 'molecules/TabBar'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Switch from '@mui/material/Switch'

const PREFIX = 'SettingsContent'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	searchInput: `${PREFIX}-searchInput`,
	block: `${PREFIX}-block`,
	addUser: `${PREFIX}-addUser`,
	contentWrapper: `${PREFIX}-contentWrapper`,
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.paper}`]: {
		margin: 'auto',
		overflow: 'hidden',
	},

	[`& .${classes.searchBar}`]: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
	},

	[`& .${classes.searchInput}`]: {
		fontSize: theme.typography.fontSize,
	},

	[`& .${classes.block}`]: {
		display: 'block',
	},

	[`& .${classes.addUser}`]: {
		marginRight: theme.spacing(1),
	},

	[`& .${classes.contentWrapper}`]: {
		margin: '40px 16px',
	},

	[`& .${classes.container}`]: {
		padding: '48px 36px 0',
	},
}))

const tabNames = ['Files', 'Rules', 'Usage']
function StorageContent({}) {
	return (
		<Root>
			<div className={classes.container}>
				<Paper className={classes.paper}>
					{/* <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
						<Toolbar>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<SearchIcon className={classes.block} color="inherit" />
								</Grid>
								<Grid item xs>
									<TextField
										fullWidth
										placeholder="Search by email address"
										InputProps={{
											disableunderline: 'true',
											className: classes.searchInput,
										}}
									/>
								</Grid>
								<Grid item>
									<Button variant="contained" className={classes.addUser}>
										Add user
									</Button>
									<Tooltip arrow title="Reload" aria-label="reload">
										<IconButton color="inherit">
											<RefreshIcon className={classes.block} color="inherit" />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar> */}

					<div className={classes.contentWrapper}>
						<FormGroup>
							<FormLabel component="legend">Settings</FormLabel>
						</FormGroup>
					</div>
				</Paper>
			</div>
		</Root>
	)
}

StorageContent.propTypes = {}

export default StorageContent
